<template>
  <b-overlay :show='isLoading' rounded='lg' opacity='0.6' spinner-variant='primary'>
    <template #overlay>
      <div class='d-flex align-items-center'>
        <b-spinner small type='grow' variant='secondary'></b-spinner>
        <b-spinner type='grow' variant='dark'></b-spinner>
        <b-spinner small type='grow' variant='secondary'></b-spinner>
      </div>
    </template>
    <b-row class='mt-4'>
      <b-col>
        <b-form >
          <router-link class="btn btn-sm btn-dark  "  size="sm"  :to="{ path: '/brands'}">
            <em class="fa fa-backward btn-sm btn-dark "></em> Back
          </router-link>
          <div class='card'>
            <div class='bg-primary p-3'>
              <h5 class='card-title mb-0 font-weight-bold'>Display Category Brands ({{this.name}})</h5>
            </div>
            <div v-if='this.totalRows==0'>
            <b-jumbotron class='bg-transparent'>
              <template #header>Oops, Data Not Found</template>
              <template #lead>
                We Didn't Found Any Category in This Brand Please Create New Category for this Brand
              </template>
              <b-button variant="primary"  class="btn btn-sm btn-dark col-sm-auto"
              type="button"  @click="createNewAction">Create New Category</b-button>
            </b-jumbotron>
            </div>
            <div class="row justify-content-end" v-else-if='this.totalRows>0'>    
              <div class="col-sm-auto mt-5 mb-0">
                <button
                  class="btn btn-sm btn-dark "
                  type="button"
                  @click="createNewAction"
                  style="width: 30px; height: 75%"
                >
                  <em class="fa fa-plus"></em>
                </button>
                <label class="col-sm-auto">Count </label>
                <strong class="col-sm-auto">{{ formatNumber(this.totalRows) }}</strong>
                </div>
              </div>
              <br/>
              <div class="card-body" v-if='totalRows>0'>
                <div class="table-responsesive">
                <b-table 
                  sticky-header="500px"
                  :items="items"
                  :fields="fields"
                  :busy="isLoading"
                  :isLoading="isLoading"
                  show-empty
                  responsive="xl"
                  hover
                  sort-icon-left
                >
                <template #cell(Name)="row">
                  {{row.item.categoriesName}}
                </template>
                <template #cell(delete-action)="row">
                  <button
                    class="btn btn-sm btn-danger "
                    type="button"
                    @click="deleteAction(row.item.categoryId,row.item.categoriesName)"
                  >
                    <em class="fa fa-trash"></em>
                  </button>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </b-form>
    </b-col>
  </b-row>
</b-overlay>
</template>
<script>
import { mapState, mapActions } from 'vuex';
export default {
  name: 'DisplayCategoryPath',
  data() {
    return {
      isLoadingSearch: false,
      items_search: [],
      debounce: null,
      id: null,
      form: {
        name:null,
      }, fields: [
        { key:'Name', label: 'Category Name'},
        { key:'delete-action', label: 'Delete'},
      ]
    };
  },
  computed: {
    ...mapState({
      totalRows: (state) => state.brands.totalRows,
      items :(state) => state.brands.items,
      isLoading : (state) => state.brands.isLoading,
      isError : (state) => state.brands.isError,
      message : (state) => state.brands.successMessage,
      name : (state) =>state.brands.name,
    }),
  },
  watch: {
    message: function () {
      if(!this.successMessage) return;
      if(this.isError){
        this.messageAlert('error', this.successMessage);  
      } else {
        this.messageAlert('success', this.successMessage);  
      }
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Brands - Category | Content Management System Ebooks Gramedia.com';
      }
    }
  },
  created() {
    this.actionGetCategoryBrands();
  },
  mounted() {
    this.actionGetCategoryBrands();
  },
  methods: {
...mapActions("brands", ["getCategoriesByBrandIdAdapter","deleteCategory"]),
    actionGetCategoryBrands() {
      let id=this.$route.params.id;
      this.id = this.$route.params.id;
      this.getCategoriesByBrandIdAdapter({id});
    },messageAlert(icon, title, timer=3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },createNewAction() {
      console.log('masuk ni', this.id);
      this.$router.push({
        path: `/brands/createCategory/${this.id}`,
      });
    },
    async deleteAction(categoryId,categoryName) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: `Delete Category ${categoryName}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            let payload={
              category_id:categoryId,
              id:this.id,
            }
            const response = await this.deleteCategory(payload);
            const {result, message } = response;
            if(result) {
              this.$swal.fire(
              'Deleted!',
              message,
              'success'
              )
            }else {
              this.$swal.fire(
              'Failed!',
              message,
              'error'
              )
            }
            this.actionGetCategoryBrands();
            this.deleteCategory(payload);
          }
        });
    },formatNumber(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
      return '0';
    },reset() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
  }
};

</script>
<style scoped>

</style>
